import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../../service/database.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatInput } from '@angular/material/input';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'wim-database-detail-actions',
  standalone: true,
  imports: [
    MatIcon,
    MatButton,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    FormsModule,
    MatDivider,
    MatInput,
    MatTab,
    MatTabGroup,
    MatTabContent
  ],
  templateUrl: './database-detail-actions.component.html',
  styleUrl: './database-detail-actions.component.scss'
})
export class DatabaseDetailActionsComponent implements OnInit {

  databaseName = '';

  constructor(private databaseService: DatabaseService,
              private messageService: CantaaMessageService,
              private router: Router,
              private route: ActivatedRoute,
              private errorHandler: CantaaErrorHandlerService,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    this.databaseName = this.route.snapshot.paramMap.get('databaseName')!;
    console.log(this.databaseName);
  }

  async onCancel() {
    await this.router.navigate(['../'], { relativeTo: this.route });
  }

  async reCreateDatabaseModal() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { question: 'Are you sure to re-create ' + this.databaseName + ' ?' },
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.reCreateDatabase();
      }
    });
  }

  async reCreateDatabase() {
    try {
      await this.databaseService.reCreateDatabase(this.databaseName);
      this.messageService.success("Database re-created");
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to re-create database: ' + this.databaseName);
    }
  }

  async copyDemoData() {
    try {
      await this.databaseService.copyDemoData(this.databaseName);
      this.messageService.success("Demo data was copied");
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to copy demo data');
    }
  }

}
