import { Component } from '@angular/core';
import { DatabaseDetailActionsComponent } from '../../../database/database-detail-actions/database-detail-actions.component';
import { DatabaseDetailComponent } from '../../../database/database-detail/database-detail.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { KeycloakUserDetailComponent } from '../keycloak-user-detail/keycloak-user-detail.component';
import { KeycloakUserRolesComponent } from '../keycloak-user-roles/keycloak-user-roles.component';

@Component({
  selector: 'wim-keycloak-user-tab-detail',
  standalone: true,
  imports: [
    DatabaseDetailActionsComponent,
    DatabaseDetailComponent,
    MatTab,
    MatTabGroup,
    KeycloakUserDetailComponent,
    KeycloakUserRolesComponent
  ],
  templateUrl: './keycloak-user-tab-detail.component.html',
  styleUrl: './keycloak-user-tab-detail.component.scss'
})
export class KeycloakUserTabDetailComponent {

}
