<mat-stepper orientation="vertical" #stepper>
  <mat-step label="Create database user" state="create_database_user">
    <p>
      Create a database user, which will be owner of the database. <br>
      Please use pattern wim_[stage]_[tenant]. <br>
      Example: wim_main_cantaa
    </p>
    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Create database" state="create_database">
    <p>
      Create a database. <br>
      Please use pattern wim_[stage]_[tenant]. <br>
      Example: wim_main_cantaa
    </p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Create organization" state="create_organization">
    <p>
      Create an organization. <br>
      Domains are not used now, but we may use it in the future. Example is cantaa.de. <br>

      Important is to define tenant, please use lowercase for tenant name. e.g. cantaa
    </p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>

  <mat-step label="Create keycloak user" state="create_keycloak_user">
    <p>
      Create a keycloak user, which will be assigned to the organization. <br>

    </p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>

  <mat-step label="Update application" state="update_application">
    <p>
      For a new tenant, we need to update the application.properties file in backend application <br>
      There is file for each stage. For example for develop there is application-develop.properties file. <br>

      Definition looks like this: <br><br>

      multitenancy.tenants.<b>cantaa.</b>datasource.driverClassName=org.postgresql.Driver <br>
      multitenancy.tenants.<b>cantaa.</b>datasource.url=jdbc:postgresql://localhost:5432/wim?currentSchema=api <br>
      multitenancy.tenants.<b>cantaa.</b>datasource.username=wim_main <br>
      multitenancy.tenants.<b>cantaa.</b>datasource.password= <br>
      <br/>
      Note: there is tenant name after .tenants. <br>
    </p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
    </div>
  </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="create_database_user">
    <mat-icon>person</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="create_database">
    <mat-icon>storage</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="create_keycloak_user">
    <mat-icon>manage-accounts</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="create_organization">
    <mat-icon>supervisor_account</mat-icon>
  </ng-template>

</mat-stepper>
