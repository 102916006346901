import { Component, inject, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DatabaseService } from '../../../service/database.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { DatabaseUserService } from '../../../service/database-user.service';

@Component({
  selector: 'wim-create-database-user-dialog',
  standalone: true,
    imports: [
        FormsModule,
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatInput,
        MatLabel
    ],
  templateUrl: './create-database-user-dialog.component.html',
  styleUrl: './create-database-user-dialog.component.scss'
})
export class CreateDatabaseUserDialogComponent {

  readonly dialogRef = inject(MatDialogRef<CreateDatabaseUserDialogComponent>);
  readonly databaseService = inject(DatabaseUserService);
  readonly errorHandler = inject(CantaaErrorHandlerService);

  readonly databaseUserName = model('');
  readonly databaseUserPassword = model('');

  async onConfirm() {
    try {
      const user = {
        name: this.databaseUserName(),
        password: this.databaseUserPassword()
      };
      await this.databaseService.createDatabaseUser(user);
      this.dialogRef.close(true);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to create database user: ' + this.databaseUserName());
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

