import { Routes } from '@angular/router';
import { LogoutComponent } from './components/logout/logout.component';
import { DatabaseListComponent } from './components/database/database-list/database-list.component';
import { DatabaseUserListComponent } from './components/database-user/database-user-list/database-user-list.component';
import { DatabaseUserDetailComponent } from './components/database-user/database-user-detail/database-user-detail.component';
import { DatabaseTabDetailComponent } from './components/database/database-tab-detail/database-tab-detail.component';
import { KeycloakUserListComponent } from './components/keycloak/keycloak-user/keycloak-user-list/keycloak-user-list.component';
import { KeycloakUserTabDetailComponent } from './components/keycloak/keycloak-user/keycloak-user-tab-detail/keycloak-user-tab-detail.component';
import { KeycloakUserDetailComponent } from './components/keycloak/keycloak-user/keycloak-user-detail/keycloak-user-detail.component';
import {
  KeycloakOrganizationListComponent
} from './components/keycloak/keycloak-organization/keycloak-organization-list/keycloak-organization-list.component';
import {
  KeycloakOrganizationDetailComponent
} from './components/keycloak/keycloak-organization/keycloak-organization-detail/keycloak-organization-detail.component';
import { GuideComponent } from './components/guide/guide/guide.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { About } from './components/about/about.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'guide', component: GuideComponent },
  { path: 'database-users', component: DatabaseUserListComponent },
  { path: 'database-users/:userName', component: DatabaseUserDetailComponent },
  { path: 'keycloak-organizations', component: KeycloakOrganizationListComponent },
  { path: 'keycloak-organizations/new', component: KeycloakOrganizationDetailComponent },
  { path: 'keycloak-organizations/:keycloakOrganizationId', component: KeycloakOrganizationDetailComponent },
  { path: 'keycloak-users', component: KeycloakUserListComponent },
  { path: 'keycloak-users/new', component: KeycloakUserDetailComponent },
  { path: 'keycloak-users/:userKeycloakId', component: KeycloakUserTabDetailComponent },
  { path: 'databases', component: DatabaseListComponent },
  { path: 'databases/:databaseName', component: DatabaseTabDetailComponent },
  { path: 'about', component: About },
  { path: 'logout', component: LogoutComponent },
];
