import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatListItem, MatListModule } from '@angular/material/list';
import { MatLine } from '@angular/material/core';

@Component({
  selector: 'wim-sidenav-items',
  standalone: true,
  imports: [
    MatButton,
    MatDivider,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    MatListItem,
    MatListModule,
    MatLine,
  ],
  templateUrl: './sidenav-items.component.html',
  styleUrl: './sidenav-items.component.scss'
})
export class SidenavItemsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }


}
