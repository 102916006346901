import { Component, model, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatInput } from '@angular/material/input';
import { DatabaseDetailComponent } from '../database-detail/database-detail.component';
import { DatabaseDetailActionsComponent } from '../database-detail-actions/database-detail-actions.component';

@Component({
  selector: 'wim-database-tab-detail',
  standalone: true,
  imports: [
    MatIcon,
    MatButton,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    FormsModule,
    MatDivider,
    MatInput,
    MatTab,
    MatTabGroup,
    MatTabContent,
    DatabaseDetailComponent,
    DatabaseDetailActionsComponent
  ],
  templateUrl: './database-tab-detail.component.html',
  styleUrl: './database-tab-detail.component.scss'
})
export class DatabaseTabDetailComponent implements OnInit {

  databaseName = model('');

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const databaseName = this.route.snapshot.paramMap.get('databaseName')!;
    console.log(databaseName);
    this.databaseName.set(databaseName)
  }

}
